// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
import "@hotwired/turbo-rails"
require("@rails/activestorage").start()
require("channels")
require("local-time").start()

// window.Rails = Rails


import 'bootswatch/dist/superhero/bootstrap.min.css';

import "stylesheets/application.scss"

document.addEventListener("turbo:load", () => {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })
})

require("trix")
require("@rails/actiontext")
import "controllers"


// Vue 3
import { createApp } from 'vue'
// const { createApp } = require("vue");
import App from '../App.vue'
// import './index.css'
import router from "../router.js";

// Vue Components
import VueGoogleMaps from "@fawmi/vue-google-maps";

const app = createApp(App)
app.use(router)
app.use(VueGoogleMaps, {
    load: {
      key: ""
    }
  })
app.mount('#app')